import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMusic,
  faHistory,
  faAngleUp,
  faAngleDown
} from "@fortawesome/free-solid-svg-icons";
import YouTube from "react-youtube";

const config = require('./config.json');
const playlistTracks = config[0].playlistTracks;
const playlistConcerts = config[0].playlistConcerts;
const apiKey = config[0].apiKey;
const apiUrl = config[0].apiUrl;

const opts = {
  height: "100%",
  width: "100%",
  playerVars: {
    autoplay: 1,
    controls: 0
    // cc_load_policy: 0,
    // fs: 0,
    // iv_load_policy: 3,
    // modestbranding: 1,
    // rel: 0,
    // showinfo: 0
  }
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: "",
      items: [],
      current: null,
      played: [],
      playlist: [],
      showplaylist: false,
      playlistview: false
    };
  }

  componentDidMount() {
    // this.init();
  }

  init() {
    this.setState(
      {
        mode: "trakcs",
        playlistview: true
      },
      this.getVideos
    );
  }

  getVideos(nextPage = "") {
    let fetchUrl = apiUrl;
    let data = null;

    if (this.state.mode === "tracks") {
      fetchUrl += `playlistId=${playlistTracks}&key=${apiKey}`;
      nextPage && (fetchUrl += `&pageToken=${nextPage}`);
    } else {
      fetchUrl += `playlistId=${playlistConcerts}&key=${apiKey}`;
      nextPage && (fetchUrl += `&pageToken=${nextPage}`);
    }

    fetch(fetchUrl)
      .then(response => response.json())
      .then(json => {
        data = json;
        if (this.state.items) {
          this.setState({ items: [...this.state.items, ...data.items] });
        } else {
          this.setState({ items: data.items });
        }
      })
      .then(() => {
        if (data.nextPageToken) {
          this.getVideos(data.nextPageToken);
        } else {
          this.getRandomVideo();
        }
      });
  }

  getRandomVideo() {
    if (this.state.items) {
      var obj_keys = Object.keys(this.state.items);
      var ran_key = obj_keys[Math.floor(Math.random() * obj_keys.length)];
      if (this.state.played.indexOf(ran_key) > -1) {
        this.getRandomVideo();
      } else {
        this.setState({
          current: this.state.items[ran_key],
          played: [...this.state.played, ran_key],
          playlist: [...this.state.played, ran_key].reverse().slice(1, 4)
        });
      }
    }
  }

  showPlaylist() {
    this.setState({
      playlistview: true,
    })
  }

  render() {
    console.debug(this.state.items);
    return (
      <div className="app">
        {this.state.current && this.state.playlistview && (
          <div className="playlistview">
            {this.state.items.map(i => (
              <div className="playlistitem" key={i.snippet.resourceId.videoId}>
                <a
                  href={`https://youtu.be/${i.snippet.resourceId.videoId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={i.snippet.thumbnails.medium.url}
                    alt={i.snippet.title}
                  />
                  <div className="playlistitemtitle">{i.snippet.title}</div>
                </a>
              </div>
            ))}
          </div>
        )}
        {this.state.mode === "" && (
          <div className="mode">
            <div
              className="tracks"
              onClick={() => {
                this.setState({ mode: "tracks" }, this.getVideos);
              }}
            >
              <div className="header">TRACKS</div>
            </div>
            <div
              className="concerts"
              onClick={() => {
                this.setState({ mode: "concerts" }, this.getVideos);
              }}
            >
              <div className="header">CONCERTS</div>
            </div>
          </div>
        )}
        {this.state.mode === "end" && (
          <div className="end">
            <div className="header">Thank you for playing</div>
          </div>
        )}
        {this.state.mode !== "" && this.state.current && (
          <div>
            <YouTube
              opts={opts}
              className={"player"}
              videoId={this.state.current.snippet.resourceId.videoId}
              onEnd={
                this.state.items.length > this.state.played.length
                  ? this.getRandomVideo.bind(this)
                  : () => this.setState({ mode: "end", current: null })
              }
            />
            <div className="nowplaying">
              <div className="nowplayingwrapper">
                {this.state.played.length > 1 && (
                  <FontAwesomeIcon
                    icon={this.state.showplaylist ? faAngleUp : faAngleDown}
                    className="toggleicon"
                    onClick={() =>
                      this.setState({
                        showplaylist: !this.state.showplaylist
                      })
                    }
                  />
                )}
                <div className="title current">
                  <a
                    href={`https://youtu.be/${
                      this.state.current.snippet.resourceId.videoId
                    }`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faMusic} className="icon" />
                    {this.state.current.snippet.title}
                  </a>
                </div>
                {this.state.played.length > 0 && this.state.showplaylist && (
                  <div className="recentlyplayed">
                    {this.state.playlist.map(p => (
                      <div
                        className="title"
                        key={this.state.items[p].snippet.resourceId.videoId}
                      >
                        <a
                          href={`https://youtu.be/${
                            this.state.items[p].snippet.resourceId.videoId
                          }`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FontAwesomeIcon icon={faHistory} className="icon" />
                          {this.state.items[p].snippet.title}
                        </a>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            {this.state.items.length > this.state.played.length && (
              <div className="getrandom">
                <span
                  className="button"
                  onClick={() => {
                    this.getRandomVideo();
                  }}
                >
                  GrooveSupply!
                </span>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default App;
